import { styled } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import { Icon } from "@iconify/react";
import { Typography, Paper, InputBase, Button, Grid } from "@mui/material";
import React, { useState } from "react";
import { m } from "framer-motion";
import { Link } from "react-router-dom";
import { Container, Box, Stack, StackProps } from "@mui/material";
import Image from "../../components/Image";
import { MotionContainer, varFade } from "../../components/animate";
import { CrowdImage } from "assets";
import { outlineButtonStyles, primaryButtonStyles } from "utils/cssStyles";

const ContentStyle = styled((props: StackProps) => <Stack spacing={8} {...props} />)(
  ({ theme }) => ({
    position: "relative",
    backgroundColor: "rgba(248, 248, 248, 1)",
    padding: theme.spacing(15, 0),
  })
);

type SearchFormProps = {
  setSearch: React.Dispatch<React.SetStateAction<string>>;
};

export default function HomeHero({ setSearch }: SearchFormProps) {
  // const theme = useTheme();

  return (
    <MotionContainer>
      <Container>
        <ContentStyle>
          <m.div variants={varFade().inRight}>
            <Stack>
              <Typography variant='h1' sx={{ color: "common.black", textAlign: "center" }}>
                Fandom Powered
              </Typography>
              <Typography
                component='span'
                variant='h1'
                sx={{ color: "common.black", textAlign: "center", fontWeight: 400 }}
              >
                by membership
              </Typography>
            </Stack>
          </m.div>
          <Stack justifyContent='center' alignItems='center' spacing={2} direction='row'>
            <Link to='/search'>
              <Button size='large' sx={primaryButtonStyles}>
                join a fandom
              </Button>
            </Link>
            <Link to='https://www.artist.soundctrl.xyz'>
              <Button size='large' variant='outlined' sx={outlineButtonStyles}>
              For Artists
              </Button>
            </Link>
          </Stack>
          <Box
            sx={{
              bgcolor: "rgba(34, 34, 34, 1)",
              borderRadius: 5,
              position: "relative",
              padding: 4,
              width: "100%",
              marginTop: "7rem !important",
              marginBottom: "3rem",
            }}
          >
            <Stack spacing={2} sx={{ padding: { md: 8 } }}>
              <Box>
                <Typography variant='h3' sx={{ color: "common.white" }}>
                  1 on 1 video calls+
                </Typography>
                <Typography variant='subtitle2' sx={{ color: "#F8F8F8" }}>
                  Book a private video call with your favorite artists
                </Typography>
              </Box>
              <Box>
                <Typography variant='h3' sx={{ color: "common.white" }}>
                  Rewards for streaming+
                </Typography>
                <Typography variant='subtitle2' sx={{ color: "common.white", width: "35ch" }}>
                  Earn points for streaming music. Use points to get rewards like merch and tickets
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant='h3'
                  sx={{
                    color: "#F8F8F8",
                    textAlign: "left",
                    fontSize: { xs: "1.4rem", sm: "2rem" },
                  }}
                >
                  Community of super fans+
                </Typography>
                <Typography variant='subtitle2' sx={{ color: "common.white" }}>
                  Find your people. Chat with other die hard fans
                </Typography>
              </Box>
              <Box>
                <Typography variant='subtitle2' sx={{ color: "common.white", width: "35ch" }}>
                  Join over 3,000 of the world’s largest music fandoms on the fastest growing
                  platform for superfans.
                </Typography>
              </Box>
            </Stack>
            <Image
              src={CrowdImage}
              alt='crowd image'
              sx={{
                height: { sm: 450, lg: 550 },
                width: { sm: 450, lg: 550 },
                position: "absolute",
                top: -65,
                right: 35,
                display: { xs: "none", sm: "block" },
              }}
            />
          </Box>
          <Paper
            elevation={0}
            component='form'
            sx={{
              p: "8px 16px",
              display: "flex",
              alignItems: "center",
              width: "100%",
              position: "relative",
            }}
          >
            <InputBase
              placeholder='Search for your favorite artist'
              inputProps={{ "aria-label": "Search for your favorite artist" }}
              onChange={(e) => setSearch(e.target.value)}
              sx={{
                bgcolor: "common.white",
                padding: "12px 24px",
                flex: 1,
              }}
            />
            <Button variant='contained' sx={{ ...primaryButtonStyles, marginLeft: 1 }} size='large'>
              search
            </Button>
          </Paper>
        </ContentStyle>
      </Container>
    </MotionContainer>
  );
}
